'use client'


import { useRouter } from 'next/navigation'
import { signIn } from 'next-auth/react'
import type { FormEvent } from 'react'
import React from 'react'

import { organizeCarts } from '@/app/_components/cart/actions'

const LoginPage = () => {
  const router = useRouter()

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)

    const email = data.get('email')
    const password = data.get('password')

    await signIn('credentials', {
      redirect: false,
      email: email,
      password,
      callbackUrl: '/admin/orders',
    })
      .then((res) => {
        if (res?.error) {
          alert(res.error)
        }
        router.push(res?.url as string)
        organizeCarts()
        router.refresh()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleGoogleLogin = async () => {
    await signIn(
      'google',
      { callbackUrl: '/admin/orders' },
      { prompt: 'login' },
    )
      .then((res) => {
        if (res?.error) {
          alert(res.error)
        }
        router.push(res?.url as string)
        organizeCarts()
        router.refresh()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    // <Container component="main" maxWidth="xs">
    //   <CssBaseline />
    //   <Box
    //     sx={{
    //       marginTop: 8,
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
    //       <TextField
    //         margin="normal"
    //         required
    //         type="email"
    //         fullWidth
    //         id="email"
    //         label="email"
    //         name="email"
    //         autoComplete="email"
    //         autoFocus
    //       />
    //       <TextField
    //         margin="normal"
    //         required
    //         fullWidth
    //         type="password"
    //         id="password"
    //         label="password"
    //         name="password"
    //         autoComplete="current-password"
    //         autoFocus
    //       />
    //       <Button
    //         type="submit"
    //         fullWidth
    //         variant="outlined"
    //         sx={{ mt: 3, mb: 2 }}
    //       >
    //         Login
    //       </Button>
    //       <Grid container>
    //         <Grid item xs>
    //           <Typography variant="body2">
    //             <Link href="/user/forget-password">
    //               パスワードを忘れた方はこちら
    //             </Link>
    //           </Typography>
    //         </Grid>
    //         <Grid item>
    //           <Typography variant="body2">
    //             <Link href="/register">新規登録</Link>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   </Box>
    // </Container>
    <div className="flex h-screen w-auto justify-center items-center">
      <button
        type="button"
        className="text-white w-48  bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55"
        onClick={handleGoogleLogin}
      >
        <svg
          className="mr-2 -ml-1 w-4 h-4"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="google"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 488 512"
        >
          <path
            fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
          ></path>
        </svg>
        Googleでログイン
      </button>
    </div>
  )
}

export default LoginPage
